<template>
  <div class="active-info">
    <div class="content ql-editor" v-html="info.content"></div>
  </div>
</template>
<script>
export default {
  name: "ActiveInfo",
  
  data() {
    return {
      id: '',
      info: ''
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getInfo();
  },
  methods: {
    getInfo(){
      this.$axios.post(`/v1/hh/characterStoryDetail`, {
        id: this.id
      }).then((res) => {
        this.info = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 28px;
  ::v-deep img{
    max-width: 100%;
  }
}
</style>
